.settings-panel-card-body {
  padding: 20px;
}

@media (max-width: 576px) {
  .settings-panel {
    padding: 0 !important;
  }
  .settings-panel-card-body {
    padding: 0 !important;
    ul {
      padding: 10px 0px !important;
    }
  }

  .all-customer-card-body {
    padding: 0 !important;
    .mdb-datatable-filter {
      width: 100%;
    }
  }

  .all-customers-start-date {
    margin-bottom: 10px !important;
  }

  .all-customers-add-button {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    height: 46px;
    width: 46px;
    border-radius: 23px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    span {
      display: none;
    }
    i {
      font-size: 24px;
      margin-right: 0 !important;
    }
  }
}
