@media (max-width: 768px) { 
    .local-number-edit-delete-button {
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
    }
    .local-number-edit-button {
        width: 70px !important;
        margin-bottom: 10px;
        padding: 0
    }
    .local-number-delete-button {
        width: 70px !important;
        padding: 0;
        
    }
 }


@media (max-width: 576px) {
    .local-number-add-number {
    position: fixed !important;
    bottom: 20px;
    right: 20px;
    z-index: 10;
    height: 46px;
    width: 46px;
    border-radius: 50% !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2),
      0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
    span {
      display: none;
    }
    i {
      font-size: 24px;
      margin-right: 0 !important;
    }
  }
}